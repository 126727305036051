import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Brand1 from '../element/brand1';
import Header2 from '../layout/header2';

const aboutbg = require('./../../assets/images/background/image-11.jpg');



class About extends Component {


    render() {
        return (
            <>
                     <Header2 />
              


                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>About Us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">About Us</li>
                        </ul>
                    </div>

                </section>


                {/* About Section Two */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/index14.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>Our Expert <br />Advisors</h2>
                                        <div class="text">Makarios Charis Limited, was established to bring affordable, effective FOREX training that works to individuals. The Institute is one of its kind, solely owned by young Nigerian entrepreneurs. The bulk of the training and mentoring provided is done at a very affordable cost.Makarios Charis Limited Institute is duly regulated and provides FOREX Training, not to make profit but as a means to give back to the society by helping to curb unemployment. Makarios Charis Limited has expert with professional skill set to teach the beginners to improve their financial blueprint, thereby turning time into money.The company makes its money from trading FOREX and the trade house is open to the students of the Institute to see their personal trading account. Our clients through our world class trading and training experience are given the opportunity not just to learn to create wealth and income, but also to be disciplined to follow the right principles which will set them apart as successful traders and not gamblers.
                                        Makarios Charis Limited is in partnership with global leading brokerage firms like Iron FX, etc, to provide you with a one stop platform for all your FOREX requirements. Some of the benefits include, but not limited to, Trading online with a CySec account, 10+ Banking and non – banking liquidity providers (Quantic group). Transparency : No hidden commission, Negative balance Protection and fast market execution.
                                        we are here to help you.

                                        </div>
                                    </div>
                                  
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/* History Section */}
                  {/* <!-- Why Choose Us Section --> */}
                  <section class="why-chooseus-section" style={{paddingTop:0}}>
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">Why Choose Us</div>
                                        <h2>Why Should You <br />Choose Us ?</h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"><span>Our Mission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"><span>Our Vission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>Our CREED</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    
                                                    <div class="text"><p>
                                                    To build a global community, devoid of religious segregation and tribal marks.
To become a people of one hope, voice, and a non-denominational growth agenda. 
                                                        </p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div class="clearfix">

                                                    <div class="text"><p>To become a community of LIKEMINDS
TRULY COMMITTED TO THE SUCCESS OF EACH OTHER
Chasing our "GROWTH", with an "UNBROKEN" FOCUS.</p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div class="clearfix">
                                               
                                                    <div class="text"><p>
                                                        <ul>
                                                        <li>Integrity</li>
                                                        <li>Excellence</li>
                                                        <li>Timeliness</li>
                                                        <li>Value</li>
                                                        <li>Quality</li>
                                                        <li>Innovation</li>
                                                        <li>Diligence</li>
                                                        </ul>
                                                        </p></div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>



                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt="" /></div>
                                    <div class="image-two"><img src={require('../../assets/images/resource/b400.png')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

               

                {/* Services Section Four */}
                <section class="services-section-four services-section-about" style={{marginBottom:0}}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">About</div>
                            <h2>OUR CORE COMPETENCE</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>ASSET MANAGEMENT</h2>
                                    <div class="text">Our team are devoted professional traders, always ready to support you all the way. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-21.png')} alt="" /></div>
                                    <h2>Trading Goals</h2>
                                    <div class="text">Learn the best of trading from us and testify of how fast we are ready to teach and exposed all the secrets of trading. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>Insurance & Planning</h2>
                                    <div class="text">We understand how difficult it is to plan for the future, so we decided to devote our self to do that for you.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-22.png')} alt="" /></div>
                                    <h2>Family Investment</h2>
                                    <div class="text">We understand the need for family to invest together so we provided features that will support your dreams of growing together. Because we understand the power of family investment.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Financial Strategy</h2>
                                    <div class="text">We know that sometimes people can be careless with saving and investment, so we as a company provides you with all the tools and supports you need to achieve your dreams.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Financial Planning</h2>
                                    <div class="text">We help you invest your money so that you don't have to worry about the future. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Sponsors Section */}


                {/* <Brand1 /> */}






                <Footer />
            </>
        )
    }
}
export default About;