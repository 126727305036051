import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

const videobg2 = require('./../../assets/images/background/image-6.jpg');


class VideoPopup2 extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <>

                <section class="video-section style-two" style={{ backgroundImage: "url(" + videobg2 + ")" }}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Learning</div>
                            <h2>Trading Masterclass</h2>
                            <h3>You are one step closer to your financial freedom</h3>
                            <p>Get trained on how to trade the FOREX market successfully with our 3 months course fully packaged to enhance your trading capacity.LEARN TO EARN With Makarios Charis Limited. The Forex Market is Unarguably the Largest financial Market in the World, with trillions of Dollars Traded Daily. With our world class training Facilities, we offer you our Professional Services And Conducive Learning & Trading environment at a Very Affordable fee.</p>
                        </div>
                        <div class="col-md-12 text-center" >
                        <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">ENROL NOW</span></button>
                        </div>
                    </div>
                </section>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='L61p2uyiMSo' onClose={() => this.setState({ isOpen: false })} />


            </>
        )
    }
}
export default VideoPopup2;