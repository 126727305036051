import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <>
                <footer class="main-footer">
                    <div class="auto-container">

                        <div class="widgets-section">
                            <div class="row clearfix">


                                <div class="column col-lg-4">
                                    <div class="footer-widget logo-widget">
                                        <div class="widget-content">
                                            <div class="footer-logo">
                                                <Link to="index.html"><img class="" src={require('../../assets/images/makarios_logo_200.png')} alt="" /></Link>
                                            </div>
                                            <div class="text">
                                            Makarios Charis Limited, was established to bring affordable, effective FOREX training that works to individuals.
                                             The Institute is one of its kind, solely owned by young Nigerian entrepreneurs. 
                                             The bulk of the training and mentoring provided is done at a very affordable cost. <a href="#" >Read More...</a>
                                            </div>
                                            <ul class="social-links clearfix">
                                                <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-vimeo-v"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-instagram"></span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div class="column col-lg-4">
                                    <div class="footer-widget links-widget">
                                        <div class="widget-content">
                                            <h3>Links</h3>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <ul>
                                                        <li><Link to={'/'}>Home</Link></li>
                                                        <li><Link to={'/about'}>About</Link></li>
                                                        <li><Link to={'/services'}>Services</Link></li>
                                                        {/* <li><Link to={'/#'}>Portfolio</Link></li> */}
                                                        <li><Link to={'/investment'}>Select Investment</Link></li>
                                                        <li><Link to={'/contact'}>Contact</Link></li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-6">
                                                    <ul>
                                                        {/* <li><Link to={'/#'}>News</Link></li> */}
                                                        {/* <li><Link to={'/#'}>Press Release</Link></li> */}
                                                        <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                                                        <li><Link to={'/terms'}>Terms</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="column col-lg-4">
                                    <div class="footer-widget instagram-widget">
                                        <h3>CONTACT US</h3>
                                        <div class="inner-box">
                                            <div class="wrapper-box">
                                               <ul>
                                                <li>#131 Old Aba Road, Opposite Mini-Okporo Police Station, Port Harcourt, Rivers state.
                                               #43 Trans-Amadi Road, Opp. Pabod Breweries, Oginigba Port Harcourt, Rivers State.</li>
                                               <li>Phone: +234070062527467</li>
                                               <li><a href="mailto:support@makarioscharis.com">support@mcharis.com</a></li>
                                               </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="auto-container">
                        <div class="footer-bottom">
                            <div class="copyright">{new Date().getFullYear("YYYY")} &copy; Copyright Right <Link to={'/'}>MCharis.com</Link></div>
                        </div>
                    </div>
                </footer>


            </>
        );
    }
}

export default Footer;