import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {baseUil} from '../../util/sitecontants'


class Menu extends Component {


    render() {
        return (
            <>
                <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                    <ul class="navigation clearfix">
                     
                        <li><Link to={'/about'}>About Us</Link></li>
                        <li><Link to={'/services-1'}>Services</Link></li>
                        
                        <li ><a href={baseUil.news}>News</a>
                            {/* <ul>
                                <li><Link to={'/blog-grid'}>Blog Grid</Link></li>
                                <li><Link to={'/blog-standard'}>Blog Standard</Link></li>
                                <li><Link to={'/blog-details'}>Blog Details</Link></li>
                            </ul> */}
                        </li>
                        <li><Link to={'/contribution'} >Contribution</Link></li>
                        <li><Link to={'/faq'}>Faq's</Link></li>
                        <li><Link to={'/contact'}>Contact</Link></li>
                    </ul>
                </div>

            </>
        )
    }
}
export default Menu;