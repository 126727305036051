import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header2 from '../layout/header2';
import HomeSlider2 from '../element/home-slider2';
import Footer from '../layout/footer';
import Testimonial1 from '../element/testimonial1'
import VideoPopup2 from './../element/video-popup2'
import '../../assets/css/color-2.css';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Lottie from 'react-lottie';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Nav, Tab, } from 'react-bootstrap';

import * as animationData from  "../../assets/images/resource/graph-animation.json"
const counterbg1 = require('./../../assets/images/background/image-5.jpg');
const touchbg1 = require('./../../assets/images/background/image-8.jpg');
const teambg1 = require('./../../assets/images/background/image-3.jpg');
const car_road = require('./../../assets/images/background/car-road.png');
const house_s = require('./../../assets/images/background/r-buildings-o.png');


const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


class Index2 extends Component {


    render() {
        return (
            <>
                <Header2 />


             

                <HomeSlider2 />


                {/* <!-- Feature Section --> */}
                <section class="feature-section" style={{display:'none'}}>
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="row">
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-team"></span></div>
                                            <h5>Management</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-money"></span></div>
                                            <h5>TAX Consume</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-assets"></span></div>
                                            <h5>Finance & Strategy</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-world"></span></div>
                                            <h5>Best Advice</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-money-1"></span></div>
                                            <h5>Insurance</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-notebook"></span></div>
                                            <h5>Criminal Case</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- About Section Two --> */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>THE WORLD <br /> OF FINANCIAL GROWTH</h2>
                                        <div class="text">We help you start your journey to financial freedom, is easy all you need to get started is to contact us now.</div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="image-one">
                                   

                                        <img src={require('../../assets/images/resource/bg_home.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                  {/* <!-- News Section --> */}
                  <section class="team-section" style={{ backgroundImage: "url(" + teambg1 + ")"}}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">About</div>
                            <h2>OUR CORE COMPETENCE</h2>
                        </div>
                        <div class="row">
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    
                                    <div class="content">
                                      
                                        <h3>LEARN THE BEST TRADING SKILLS</h3>
                                        <div class="text">We expand your skills to the best trading platforms in the world.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    
                                    <div class="content">
                                        
                                        <h3>UNDERSTANDING THE SECRETS</h3>
                                        <div class="text">We exposed all the secrets of digital trading and ready to share with you.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    
                                    <div class="content">
                                  
                                        <h3>THE HOME OF DIGITAL ASSET TRADING </h3>
                                        <div class="text">We hire the best of the best and we ensure better learning environment.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <VideoPopup2 />
                {/* <!-- Team Section --> */}
               
                  {/* <!-- Why Choose Us Section --> */}
                  <section class="why-chooseus-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">Why Choose Us</div>
                                        <h2>Why Should You <br />Choose Us ?</h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"><span>Our Mission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"><span>Our Vission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>Our CREED</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    
                                                    <div class="text"><p>
                                                    To build a global community, devoid of religious segregation and tribal marks.
To become a people of one hope, voice, and a non-denominational growth agenda.  
                                                        </p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div class="clearfix">

                                                    <div class="text"><p>To become a community of LIKEMINDS
TRULY COMMITTED TO THE SUCCESS OF EACH OTHER
Chasing our "GROWTH", with an "UNBROKEN" FOCUS.</p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div class="clearfix">
                                               
                                                    <div class="text"><p>
                                                        <ul>
                                                        <li>Integrity</li>
                                                        <li>Excellence</li>
                                                        <li>Timeliness</li>
                                                        <li>Value</li>
                                                        <li>Quality</li>
                                                        <li>Innovation</li>
                                                        <li>Diligence</li>
                                                        </ul>
                                                        </p></div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>



                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt="" /></div>
                                    <div class="image-two"><img src={require('../../assets/images/resource/b400.png')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="spotlight-section services-section-two">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{backgroundImage:`url(${car_road})`}}>
                            <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                     
                                     <h2>BUY A CAR WITHOUT BREAKING YOUR NERVES</h2>
                                         <div class="text">Pay only 60% and hub into your dream car home.</div>
                                     </div>
                            </div>
                            <div className='right-column' style={{backgroundImage:`url(${house_s})`}}>
                                 <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                      
                                        <h2>YOU TOO CAN OWN A LUXURIOUS HOME</h2>
                                        <div class="text">How? Simply give us 60% of the total cost of the house you desire to own, and we hand you the keys. </div>
                                    </div>
                                    
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Testimonial Section --> */}
                <Testimonial1 />

              
                        {/* <!-- Services Section Two --> */}
                        <section class="services-section-two">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{ backgroundImage: "url(" + touchbg1 + ")" }}>
                                <div class="sec-title light">
                                    <div class="sub-title">Get In Touch</div>
                                    <h2>Get Us Here Or <br /> Share Details</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div class="contact-form">
                                    <form method="post" action="https://wallet.makarioscharis.com/sendemail" id="contact-form">
                                        <div class="row clearfix">
                                            <div class="col-md-12 form-group">
                                                <input type="text" name="username" placeholder="Enter name here......" required="" />
                                                <i class="fas fa-user"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <input type="email" name="email" placeholder="Enter email here......" required="" />
                                                <i class="fas fa-envelope"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <textarea name="message" placeholder="Enter message here......"></textarea>
                                                <i class="fas fa-edit"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="right-column">
                                <div class="services-content">
                                    <div class="sec-title">
                                       
                                        <h2>WHY CHOOSE US</h2>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-16.png')} alt="" /></div>
                                        <h2>Investment Plan</h2>
                                        <div class="text">Invest now to secure the future. See our investment plans that will suit your pocket. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-17.png')} alt="" /></div>
                                        <h2>Learn</h2>
                                        <div class="text">We train you to become an expert, and expand your knowledge to the world of digital investment. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-18.png')} alt="" /></div>
                                        <h2>Earn</h2>
                                        <div class="text">Investing with us you earn up to 20% of your investment monthly and we support you all the way.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <Footer />
            </>
        )
    }
}
export default Index2;