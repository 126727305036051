import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import ServiceFilter1 from '../element/service-filter1'
import Header2 from '../layout/header2';

const aboutbg = require('./../../assets/images/background/image-11.jpg');
// const casebg = require('./../../assets/images/background/image-14.jpg');

class Services1 extends Component {


    render() {
        return (
            <>
                 <Header2/>

                 {/* <!--Search Popup--> */}
              
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>What We Do</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Services</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}


                {/* <!-- Services Section Three --> */}
                <section class="services-section-three style-two">
                    <div class="auto-container">
                        <div class="row">
                            {/* <!-- Services Block One --> */}
                            <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-team"></span><span class="round-shape"></span></div>
                                    <h3>Business Management</h3>
                                    <div class="text">
                                    Allow us give your business the required financial cushion, and as well inject our sage on business management with proven results.
Our experts are always available to bring you onboard.
                                    <a href="/contact">Talk to a consultant</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Services Block One --> */}
                            {/* <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-money"></span><span class="round-shape"></span></div>
                                    <h3>Criminal Case</h3>
                                    <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                </div>
                            </div> */}
                            {/* <!-- Services Block One --> */}
                            <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-assets"></span><span class="round-shape"></span></div>
                                    <h3>Transport & Logistics</h3>
                                    <div class="text">
                                    We care, so you carefree! by prioritizing your safety.
Our goal is to drive you in style, oath your safety, and elevate your prestige.
Experience a completely customized and flexible trip and delivery of a lifetime with experts you can trust.
Our round-the-clock local and international support, is our signet of care and authenticity.
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Services Block One --> */}
                            <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-world"></span><span class="round-shape"></span></div>
                                    <h3>Smart Invest</h3>
                                    <div class="text">
                                    Our Smart Invest system allows you create an ocean of wealth through little drops of daily, weekly and monthly savings, with a mouth-watering ROI.
Reach Out To Our Marketing Team.
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Services Block One --> */}
                            <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-notebook"></span><span class="round-shape"></span></div>
                                    <h3>Makarios Mall</h3>
                                    <div class="text">Fast, Fresh, Automated…
Built to last, with an evidence that speaks.</div>
                                </div>
                            </div>
                            {/* <!-- Services Block One --> */}
                            <div class="service-block-one col-lg-4">
                                <div class="inner-box">
                                    <div class="icon"><span class="flaticon-money-1"></span><span class="round-shape"></span></div>
                                    <h3>Makarios Credit</h3>
                                    <div class="text">
                                    Lifting that your dream project and financing your contract shouldn’t give you sleepless nights.
That is why we have created this unique financing channel with a ridiculous interest rate you wouldn’t believe to be real.
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>

                {/* <!-- Case Studies --> */}
                {/* <ServiceFilter1/> */}


                 <Footer/>
            </>
        )
    }
}
export default Services1;