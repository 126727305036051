import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';


const aboutbg = require('./../../assets/images/background/image-11.jpg');



class Error404 extends Component {


    render() {
        return (
            <>
                <Header />

          


                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>404</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">404</li>
                        </ul>
                    </div>

                </section>

                {/* Error Page */}
                <section class="error-section">
                    <div class="auto-container">
                        <div class="inner-section">
                            <h1>404</h1>
                            <h2>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h2>
                            <div class="text">Try the search below to find matching pages:</div>
                            
                          
                            
                        </div>
                    </div>
                </section>

	            {/* Error Page */}

                <Footer />

            </>
        )
    }
}
export default Error404;