import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Brand1 from '../element/brand1'
import PortfolioFilter2 from '../element/portfolio-filter2'

const aboutbg = require('./../../assets/images/background/image-11.jpg');


class Portfolio2 extends Component {


    render() {
        return (
            <>
                <Header/>

           
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Our Works</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Portfolio</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}



                {/* <!-- Case Studies --> */}
                <PortfolioFilter2 />


                {/* <!-- Sponsors Section --> */}
                <Brand1/>
                
                <Footer/>
            </>
        )
    }
}
export default Portfolio2;