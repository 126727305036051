import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const bgimg7 = require('./../../assets/images/background/image-7.jpg');

const Testimonial1_content = [
    {
        thumb: require('./../../assets/images/resource/avatar_2.png'),
        author: 'Rosemary Anosike',
        designation: 'Customer',
        title: '...',
        description:
            'I invested 1 million in Makarios on the 5th of June and on the 5th of July I got an alert of 200k ROI.  ',

    },
    {
        thumb: require('./../../assets/images/resource/avatar_2.png'),
        author: 'John Emeka',
        designation: 'Customer',
        title: '...',
        description:
            'Makarios is paying and they are reliable.I took a bold step to invest in Makarios before the outbreak of COVID-19 and my financial status has changed completely with monthly alert from Makarios.',

    },
    {
        thumb: require('./../../assets/images/resource/avatar_2.png'),
        author: 'Violet Freeman',
        designation: 'Customer',
        title: '...',
        description:
            'I have consistently received 20% return of investment from Makarios and this has reduced the impact of the pandemic for me and my family.',

    },
    // {
    //     thumb: require('./../../assets/images/resource/avatar_2.png'),
    //     author: 'Alexer Valvin',
    //     designation: 'Founder, Coxer IT',
    //     title: 'Design Quality',
    //     description:
    //         'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    // },
    // {
    //     thumb: require('./../../assets/images/resource/thumb-5.jpg'),
    //     author: 'Alexer Valvin',
    //     designation: 'Founder, Coxer IT',
    //     title: 'Design Quality',
    //     description:
    //         'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    // },
    // {
    //     thumb: require('./../../assets/images/resource/thumb-6.jpg'),
    //     author: 'Alexer Valvin',
    //     designation: 'Founder, Coxer IT',
    //     title: 'Design Quality',
    //     description:
    //         'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    // },
]


class Testimonial1 extends Component {


    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false
        };
        return (
            <>
                <section class="testimonial-section" style={{ backgroundImage: "url(" + bgimg7 + ")" }}>
                    <div class="auto-container">
                        <div class="sec-title">
                            <div class="sub-title">Testimonials</div>
                            <h2>Clients Feedback</h2>
                        </div>
                        <div class="row">
                            <div className="col-xl-12">
                                <Slider {...settings} className="theme_carousel">
                                    {Testimonial1_content.map((item, id) => (
                                        <div class="slide-item" key={id}>
                                            {/* <!-- Testimonial-block --> */}
                                            <div class="testimonial-block">
                                                <div class="inner-box">
                                                    <div class="icon"><span class="fal fa-star"></span></div>
                                                    <div class="quote"><span class="fal fa-quote-left"></span></div>
                                                    <h4>{item.title}</h4>
                                                    <div class="text">{item.description} </div>
                                                </div>
                                                <div class="author-info">
                                                    <div class="thumb"><img src={item.thumb} alt="" height="150" /></div>
                                                    <h4>{item.author}</h4>
                                                    <div class="designation">{item.designation}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
export default Testimonial1;