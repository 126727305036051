
import React, { Component } from 'react';

const slider = ()  => {
  
    return (
        <>
            <div > welcome</div>
        </>
    )
}

export default slider