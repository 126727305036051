import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header2 from '../layout/header2';
import Footer from '../layout/footer'
import {baseUil} from  '../../util/sitecontants';
import '../../assets/css/investment.css';
import $ from 'jquery';
import * as CurrencyFormat from 'react-currency-format';
import percentage from 'calculate-percentages'
const aboutbg = require('./../../assets/images/background/image-11.jpg');
const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');


class Team extends Component {
    
     state = {
         value: "",
        formatedValue: 0,
        newfont:""
    }
     

    addNumber = (event,number) => {

        var event = new Event('input', { bubbles: true });
        this.amountInput.dispatchEvent(event);

        this.setState({value: this.state.value + Number(number) });

        this.changeValue(this.state.value);

          
        // return false;
    }

     changenumber = (evt) => {
        var theEvent = evt || window.event;
        console.log(evt.target.value);

        const value = evt.target.value.replace(/\D/g, "");


        if(evt.target.value.length > 7){
            this.setState({newfont: "24px"})
        }
        else{
            this.setState({newfont: "1.9rem"})
        }
        
        this.changeValue(evt.target.value);
   

    }


    changeValue = (value) => {


        if(value >= 360000 || value <= 5000000){
        
            this.setState({formatedValue: percentage.of(20, value) });
          }
          else if(value >= 5000000 || value <= 49000000){

            this.setState({formatedValue: percentage.of(15,value) });

          }
          else if(value >= 49000000 || value >= 149000000){
            this.setState({formatedValue: percentage.of(10, value) });
          }


        if(value < 149000000){
            return false;
         }
                

    }
    componentDidMount() {
        // $(".val").click(function(e){
      
        //     var a = $(this).attr("href");
        //     if(a != undefined){
                
        
        //         // $(".outcome").val($(".outcome").val() + a);
        //     }
        //     e.preventDefault();
            
        // });
        // $(".equal").click(function(){
        //     $(".outcome").val(eval($(".outcome").val()));
        //     $(".screen").html(eval($(".outcome").val()));
        // });
        // $(".clear").click(function(){
        //     $(".outcome").val("");
        //     $(".screen").html("");
        // });
        // $(".min").click(function(){
        //     $(".cal").stop().animate({
        //         width: "0px", height: "0px", marginLeft: "700px", marginTop: "1000px"
        //     }, 500);
        //     setTimeout(function(){$(".cal").css("display", "none")}, 600);
        // });
        // $(".close").click(function(){
        //     $(".cal").css("display", "none");
        // })
    
    }


    render() {
        return (
            <>
                <Header2/>


                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="/">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>CONTRIBUTION</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">contribution</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}

                <section class="price-area" id="price">
                    <div class="container">

               

                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="rt-section-title-wrapper">
                                    <h2 class="rt-section-title">Affordable Pricing for All</h2>
                                    <p>You are expected to make a 5% ONE OFF payment, to buy share, which qualifies you to be a registered member of MCHARIS COOPERATIVE.
You can as well instruct us to deduct this from your DIVIDEND ( formerly ROI )</p>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-80"></div>
                        <div class="row">
                            <div class="col-lg-4 mx-auto text-center col-md-6">
                                <div class="single-price-table">
                                    <div class="price-design-elemnts one"></div>
                                    <div class="price-design-elemnts two"></div>
                                    <div class="price-design-elemnts three"></div>
                                    <div class="price-design-elemnts four"></div>
                                    <div class="price-header">
                                        <span class="package-title">STANDARD</span>
                                        <span class="package-amount">Min Contribution</span>
                                        <div class="price-sjk">
                                            <span>₦360,000.00</span>
                                        </div>

                                        <span class="package-amount">Max Contribution</span>
                                        <div class="price-sjk">
                                            <span>₦5, 000,000.00</span>
                                        </div>

                                    

                                    </div>
                                    <div class="price-body">
                                        <ul>
                                            <li>20% Monthly</li>
                                            <li>Contribution Monthly</li>
                                            <li><strong>Duration: 6 months</strong></li>
                                            <li>Capital pay back at</li>
                                            <li> the end of 6months</li>
                                        </ul>
                                    </div>
                                    <div class="price-footer">
                                        
                                    <a href={`${baseUil.baseURL}?invest=1`}>Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mx-auto text-center col-md-6">
                            <div class="single-price-table">
                                    <div class="price-design-elemnts one"></div>
                                    <div class="price-design-elemnts two"></div>
                                    <div class="price-design-elemnts three"></div>
                                    <div class="price-design-elemnts four"></div>
                                    <div class="price-header">
                                        <span class="package-title">PREMIUM</span>
                                        <span class="package-amount">Min Contribution</span>
                                        <div class="price-sjk">
                                            <span>₦5,000,000</span>
                                        </div>

                                        <span class="package-amount">Max Contribution</span>
                                        <div class="price-sjk">
                                            <span>₦49,000,000</span>
                                        </div>

                                    

                                    </div>
                                    <div class="price-body">
                                        <ul>
                                            <li>15% Monthly</li>
                                            <li>Contribution Monthly</li>
                                            <li><strong>Duration: 6 months</strong></li>
                                            <li>Capital pay back at</li>
                                            <li> the end of 6months</li>
                                        </ul>
                                    </div>
                                    <div class="price-footer">
                                    <a href={`${baseUil.baseURL}?invest=2`}>Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mx-auto text-center col-md-6">
                            <div class="single-price-table">
                                    <div class="price-design-elemnts one"></div>
                                    <div class="price-design-elemnts two"></div>
                                    <div class="price-design-elemnts three"></div>
                                    <div class="price-design-elemnts four"></div>
                                    <div class="price-header">
                                        <span class="package-title">DELUXE</span>
                                        <span class="package-amount">Min Contribution</span>
                                        <div class="price-sjk">
                                            <span>₦49,871,017.07</span>
                                        </div>

                                        <span class="package-amount">Max Contribution</span>
                                        <div class="price-sjk">
                                            <span>Unlimited</span>
                                        </div>

                                    

                                    </div>
                                    <div class="price-body">
                                        <ul>
                                            <li>10% Monthly</li>
                                            <li>Contribution Monthly</li>
                                            <li><strong>Duration: 6 months</strong></li>
                                            <li>Capital pay back at</li>
                                            <li> the end of 6months</li>
                                        </ul>
                                    </div>
                                    <div class="price-footer">
                                        
                                        <a href={`${baseUil.baseURL}?invest=3`}>Get Started</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="services-section-two">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{background:'#ddd'}}>
                                <div class="sec-title">
                                    
                                    <h2>Do the math</h2>
                                    <div class="sub-title">See how much your conribution returns when you start with us.</div>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div >
                               

                                </div>
                            </div>
                            <div class="right-column" style={{background:'#303030'}}>
                                <div class="calculator-content">
                                   <div class="cal-box">
                                   <div class="wrap">
                                <div class="cal-bg">
                                <div class="cal-body">
                                 
                                    <span class="title">Your contribution calculate</span>
                                    <input type="tel" onChange={ev => this.changenumber(ev)} ref={ ref => this.amountInput2 = ref} maxLength={12}  pattern="[0-9]{5}" class="outcome screen" />
                                    <span class="currency">{baseUil.currency}</span>
                                    <div class="amount_cal row">
                                        {/* <div class="account col-6 text-left">
                                            <span>Invested Amount</span><br/>
                                            <span class="amount_invest" style={{fontSize:this.state.newfont}}>
                                                <CurrencyFormat  placeholder="0.00" value={this.state.value >= 100 ? this.state.value: 0.00} displayType={'text'} thousandSeparator={true} prefix={baseUil.currency} /></span>
                                        </div> */}
                                        <div class="account col-6 text-left">
                                            <span>ROI Returns</span><br/>
                                            <span class="roi_invest" style={{fontSize:this.state.newfont}}>
                                                <CurrencyFormat placeholder="0.00"  value={this.state.formatedValue} displayType={'text'} thousandSeparator={true} prefix={baseUil.currency} /></span>
                                        </div>
                                    </div>
                                    <ul class="buttons">
                                      
                                     <li></li>
                                        
                                    </ul>
                                </div>
                                    </div>

                                    
                                    </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        






                <Footer/>
            </>
        )
    }
}
export default Team;